import axios from "axios";
export async function validateTrackingUrl(data, hostUrl) {
  try {
    const response = await axios.post(
      hostUrl,
      { data }
    );
    return response;
  } catch (e) {
    console.log(e);
  }
}
